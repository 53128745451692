<template>
  <div id="guider">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />导游详情
        </van-col>
      </van-row>
    </div>
    <div class="guider-wrapper">
      <div class="guider-container">
        <van-row>
          <van-col span="12"
            ><div class="guider-text text-left">姓名：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.name ? guide.name : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">导游证号：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.tourist_card ? guide.tourist_card : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">导游级别：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.level ? guide.level : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">所属旅行社：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.travel_company ? guide.travel_company : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">合同：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.travel_company ? guide.travel_company : "暂无" }}
            </div></van-col
          >
        </van-row>
      </div>
      <div
        class="message-tuan"
        v-for="(item, index) in guide.travel_lists"
        :key="index"
        @click="onItemClick(item)"
      >
        <van-row class="tuan-info">
          <van-col span="10">
            <div class="tuan-start-time text-left">{{ item.start_date }}</div>
            <div class="tuan-start-address text-left">中南百草园</div>
          </van-col>
          <van-col span="4">
            <div class="need-time">2天</div>
            <div class="tuan-items">出团详情</div>
          </van-col>
          <van-col span="10">
            <div class="tuan-end-time text-right">{{ item.end_date }}</div>
            <div class="tuan-end-address text-right">欢乐风暴</div>
          </van-col>
        </van-row>
        <div class="start-date">出发日期：{{ item.start_date }}</div>
        <div class="tuan-ren-number">
          团队人数：{{ item.tourists_count ? item.tourists_count : 0 }}人
        </div>
        <div :class="[stateClass[item.status]]">{{ item.status_text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import vueQr from "vue-qr";
export default {
  name: "guider-item",
  data() {
    const _self = this;
    return {
      avatar: "",
      code: "",
      guide: {},
      stateClass: [
        "status-btn-plan",
        "status-btn-ing",
        "status-btn-completed",
        "status-btn-cancel",
      ],
      downloadData: {
        url: "https://www.baidu.com",
        icon: "",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onItemClick(item) {
      this.$router.push({
        path: "/tuan/item",
        query: {
          id: item.id,
        },
      });
    },
    async get_guide(id) {
      const res = await this.$apis.get_guide(id);
      this.guide = res.data;
    },
  },
  components: { vueQr },
  created() {
    const id = this.$route.query.id;
    if (!id) {
      Toast("没有id");
      return false;
    }
    this.get_guide(id);
  },
};
</script>

<style scoped>
#guider {
  height: 100vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.guider-wrapper {
  padding: 0.4rem;
}
.guider-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;
  position: relative;
  /* height: 80vh; */
}
.guider-avatar {
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.guider-avatar img {
  width: 100%;
  height: 100%;
}

.guider-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 160px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.message-tuan {
  background: #fff;
  padding: 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-top: 10px;
}
.tuan-info {
  /* margin-top: 1.6rem; */
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.status-btn-cancel {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #6f6f6f23;
  color: #606060;
  padding: 2px 0.2rem;
}
.status-btn-completed {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #13C2C212;
  color: #13C2C2;;
  padding: 2px 0.2rem;
}
.status-btn-plan {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #4168EE;
  background: #4168EE12;;
  padding: 2px 0.2rem;
}
.status-btn-ing {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #fff;
  background: #4168EE;;
  padding: 2px 0.2rem;
}
</style>
